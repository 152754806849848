export const hardware = [
	{
		url: 'https://amzn.to/4bJirkK',
		img: require('../imgs/whatuse/asus.webp'),
		title: 'Notebook ASUS TUF A15 15,6" Grey',
		text:''
	},
	{
		url: 'https://amzn.to/3UUyR3b',
		img: require('../imgs/whatuse/monitor.webp'),
		title: 'SAMSUNG 34" Odyssey G5 Ultra-Wide Monitor',
		text:''
	},
	{
		url: 'https://amzn.to/3UT3XIh',
		img: require('../imgs/whatuse/mouse.webp'),
		title: 'Mouse Logitech G G502 X PLUS Wireless',
		text:''
	},
	{
		url: 'https://amzn.to/3KckySr',
		img: require('../imgs/whatuse/keyboard.webp'),
		title: 'Keyboard Razer Huntsman V3 Pro',
		text:''
	},
	{
		url: 'https://amzn.to/4bnVzaK',
		img: require('../imgs/whatuse/camera.webp'),
		title: 'Nikon Z8 + Z 24-120 f/4 S mirrorless camera',
		text:''
	}
]


export const hosting = [
	{
		url: 'https://www.netsons.com/manage/aff.php?aff=8880',
		img: require('../imgs/ads/netsons-logo.webp'),
		title: 'Netsons',
		text:''
	},
	{
		url: 'https://www.siteground.com/index.htm?afcode=bb1c407e311ddd8bd12bb288089bbc79',
		img: require('../imgs/ads/siteground-logo.webp'),
		title: 'Siteground',
		text:''
	},
	{
		url: 'https://kinsta.com/?kaid=TQTYAAESTQIJ',
		img: require('../imgs/whatuse/kinsta.webp'),
		title: 'Kinsta',
		text:''
	},
	{
		url: 'https://www.hostg.xyz/aff_c?offer_id=6&aff_id=159965',
		img: require('../imgs/whatuse/hostinger.webp'),
		title: 'Hostinger',
		text:''
	}
]


export const policy = [
	{
		url: 'https://iubenda.refr.cc/travolgi',
		img: require('../imgs/whatuse/iubenda.svg'),
		title: 'Iubenda',
		text:'',
	},
	{
		url: 'https://www.cookieyes.com/welcome/?ref=njawmjg',
		img: require('../imgs/whatuse/cookieyes.svg'),
		title: 'Cookieyes',
		text:'',
	}
]


export const software = [
	{
		url: 'https://www.templatemonster.com/?utm_campaign=site_travolgi&utm_source=travolgi&utm_medium=referral&aff=travolgi',
		img: require('../imgs/whatuse/templatemonster.svg'),
		title: 'TemplateMonster',
		text:''
	},
	{
		url: 'https://www.elegantthemes.com/affiliates/idevaffiliate.php?id=75942',
		img: require('../imgs/whatuse/divi.svg'),
		title: 'Divi',
		text:''
	},
	{
		url: 'https://be.elementor.com/visit/?bta=212949&brand=elementor',
		img: require('../imgs/whatuse/elementor.svg'),
		title: 'Elementor',
		text:''
	},
	{
		url: 'https://code.visualstudio.com/',
		img: require('../imgs/whatuse/vscode.svg'),
		title: 'Visual Studio Code',
		text:''
	}
]


export const images = [
	{
		url: 'https://submit.shutterstock.com/?rid=387743023&language=it',
		img: require('../imgs/whatuse/shutterstock.svg'),
		title: 'Shutterstock',
		text:''
	},
	{
		url: 'https://www.pexels.com',
		img: require('../imgs/whatuse/pexel.svg'),
		title: 'Pexel',
		text:''
	},
	{
		url: 'https://unsplash.com',
		img: require('../imgs/whatuse/unsplash.svg'),
		title: 'Unsplash',
		text:''
	}
]
