import kinstaHost from '../imgs/ads/kinsta-hosting.webp';
import kinstaApp from '../imgs/ads/kinsta-app-hosting.webp';
import netsonsLogo from '../imgs/ads/netsons-logo.webp';
import sitegroundLogo from '../imgs/ads/siteground-logo.webp';
import hostinger from '../imgs/ads/hostinger.webp';
import htmlTemplates from '../imgs/ads/templatemonster-html-templates.webp';
import wpTemplates from '../imgs/ads/templatemonster-wp-templates.webp';
import templatemonster from '../imgs/ads/templatemonster.webp';
import monsterone from '../imgs/ads/monsterone.webp';
import astroTheme from '../imgs/ads/astro-theme.webp';
import amazonBusiness from '../imgs/ads/amazon-business.webp';
import amazon from '../imgs/ads/amazon.webp';

export default [
	<a href="https://www.cookieyes.com/welcome/?ref=njawmjg&tap_a=122257-962fde" target="_blank">
		<img src="https://static.tapfiliate.com/63982d5cc50d6877256748.jpeg?a=122257-962fde&s=3962507-cf8989" alt="CookieYes: Better design, Better experience • Ads Travolgi Themes" />
	</a>,

	<a href="https://www.cookieyes.com/welcome/?ref=njawmjg&tap_a=122260-72d8d1" target="_blank">
		<img src="https://static.tapfiliate.com/63982f5c3a28a989114937.jpeg?a=122260-72d8d1&s=3962507-cf8989" alt="Best GDPR cookie consent tool • Ads Travolgi Themes" />
	</a>,
	
	<a href="https://kinsta.com/wordpress-hosting/?kaid=TQTYAAESTQIJ" target="_blank">
		<img src={kinstaHost} alt="Kinsta 4 months OFF annual WordPress Hosting • Ads Travolgi Themes" />
	</a>,

	<a href="https://kinsta.com?kaid=TQTYAAESTQIJ" target="_blank">
		<img src={kinstaApp} alt="Kinsta Application Hosting • Ads Travolgi Themes" />
	</a>,

	<a href="https://www.siteground.com/go/2zymhx0rc0" target="_blank">
		<img src="https://siteground.com/static/affiliate/en/USD/general_EN_USD_general-hosting-square-light.jpg" alt="SiteGround Hosting • Ads Travolgi Themes" />
	</a>,

	<a href="https://www.hostg.xyz/aff_c?offer_id=6&aff_id=159965" target="_blank" title="Hostinger - Everything you need for your website: Click Here Now!">
		<img src={hostinger} alt="Hostinger Web Hosting • Ads Travolgi" />
	</a>,
	
	<a href="https://be.elementor.com/visit/?bta=212949&nci=5390" target="_blank">
		<img src="https://elementor.ck-cdn.com/tn/serve/?cid=808422" alt="Elementor logo • Ads Travolgi Themes" />
		<img src="https://elementor.ck-cdn.com/tn/serve/?cid=808436" alt="Elementor Create Entire WordPress Websites in a Flash • Ads Travolgi Themes" />
	</a>,

	<a href="https://be.elementor.com/visit/?bta=212949&nci=5550" target="_blank">
		<img src="https://elementor.ck-cdn.com/tn/serve/?cid=808429"  alt="Elementor Web Creation with AI • Ads Travolgi Themes" />
	</a>,

	<a href="https://be.elementor.com/visit/?bta=212949&nci=5358" target="_blank">
		<img src="https://elementor.ck-cdn.com/tn/serve/?cid=808439"  alt="Elementor Build With Drag & Drop Editor • Ads Travolgi Themes" />
	</a>,

	<a href="https://be.elementor.com/visit/?bta=212949&nci=5542" target="_blank">
		<img src="https://elementor.ck-cdn.com/tn/serve/?cid=808579" alt="Elementor Build & Host Your WordPress Website • Ads Travolgi Themes" />
	</a>,

	<a href="https://www.netsons.com/manage/aff.php?aff=8880" target="_blank">
		<img src={netsonsLogo} alt="Netsons Hosting • Ads Travolgi Themes" />
	</a>,

	<a href="https://www.siteground.com/index.htm?afcode=bb1c407e311ddd8bd12bb288089bbc79" target="_blank">
		<img src={sitegroundLogo} alt="SiteGround Hosting • Ads Travolgi Themes" />
	</a>,

	<a href="https://www.elegantthemes.com/affiliates/idevaffiliate.php?id=75942_5_1_21" target="_blank">
		<img src="https://www.elegantthemes.com/affiliates/media/banners/divi_250x250.jpg" alt="Divi WordPress Theme • Ads Travolgi Themes" />
	</a>,

	<a href="https://www.templatemonster.com/?utm_source=website_travolgi&aff=travolgi" target="_blank">
		<img src="//affiliates.templatemonster.com/accounts/default1/db14wdodxd/1f6fce31.png" alt="Travolgi • Certified TemplateMonster Partner" />
	</a>,

	<a href="https://www.templatemonster.com/wordpress-themes.php?utm_campaign=sidebar&utm_source=website_travolgi&aff=travolgi" target="_blank">
		<img src={wpTemplates} alt="TemplateMonster Wordpress Website Themes • Ads Travolgi Themes" />
	</a>,

	<a href="https://www.templatemonster.com?utm_campaign=sidebar&utm_source=website_travolgi&aff=travolgi" target="_blank">
		<img src={templatemonster} alt="TemplateMonster Marketplace • Ads Travolgi Themes" />
	</a>,

	<a href="https://www.templatemonster.com/website-templates.php?utm_campaign=sidebar&utm_source=website_travolgi&aff=travolgi" target="_blank">
		<img src={htmlTemplates} alt="TemplateMonster Website Templates • Ads Travolgi Themes" />
	</a>,

	<a href="https://monsterone.com/?utm_campaign=sidebar&utm_source=website_travolgi&aff=travolgi" target="_blank">
		<img src={monsterone} alt="MonsterOne • Ads Travolgi Themes" />
	</a>,

	<a href="https://www.templatemonster.com/website-templates/astro-html-crypto-and-multipurpose-website-template-299220.html?utm_campaign=sidebar_travolgi&utm_source=blog_travolgi&utm_medium=referral&aff=travolgi" target="_blank">
		<img src={astroTheme} alt="Astro Crypto and Multipurpose html5 Website template • Ads Travolgi Themes" />
	</a>,

	<a href="https://www.amazon.it/business/register/org/landing?tag=travolgi-21&linkCode=ur1" target="_blank">
		<img src={amazonBusiness} alt="Amazon Business • Ads Travolgi Themes" />
	</a>,

	<a href="https://www.amazon.it/?tag=travolgi-21&linkCode=ur1" target="_blank">
		<img src={amazon} alt="Amazon Basic • Ads Travolgi Themes" />
	</a>
]
