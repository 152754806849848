export default function ThemesOverlay({ img, themeName, category, url }) {
	return (
		<article className="project browserbar">
			<img src={img} alt={`${themeName} • Travolgi Themes`} title={`${themeName} • Travolgi Themes`} />
			
			<div className="info">
				<h2 className="mb-0">{themeName}</h2>
				<p>{category}</p>
				<a href={url} className="btn self-end" target="_blank">Details</a>
			</div>
		</article>
	);
}