import WhyHaveWebsite from '../views/blog/WhyHaveWebsite';
import HolidayDealsTravolgi from '../views/blog/HolidayDealsTravolgi';
import HalloweenSaleTemplateMonster from '../views/blog/HalloweenSaleTemplateMonster';
import TopBlackFriday2022 from '../views/blog/TopBlackFriday2022';
import HowDealNft from '../views/blog/HowDealNft';
import WpAnniversary from '../views/blog/WpAnniversary';
import WpLanding from '../views/blog/WpLanding';
import HostingGuide from '../views/blog/HostingGuide';


export default [
	{
		imgs: {
			v: require('../imgs/blog/ultimate-hosting-guide.webp'),
			h: require('../imgs/blog/ultimate-hosting-guide-h.webp')
		},
		title: 'The ultimate guide to Hosting: choose the best hosting for your website and amaze your visitors!',
		slug: 'the-ultimate-guide-to-hosting-choose-the-best-hosting-for-your-website-and-amaze-your-visitors',
		date:'28 June 2023',
		category: 'guide',
		content: <HostingGuide />
	},
	{
		imgs: {
			v: require('../imgs/blog/wp-landing.webp'),
			h: require('../imgs/blog/wp-landing-h.webp')
		},
		title: 'How to craft a Landing Page in WordPress and boost your traffic?',
		slug: 'how-to-craft-a-landing-page-in-wordpress-and-boost-your-traffic',
		date:'13 May 2023',
		category: 'perplexity',
		content: <WpLanding />
	},
	{
		imgs: {
			v: require('../imgs/blog/wp-anniversary.webp'),
			h: require('../imgs/blog/wp-anniversary-h.webp')
		},
		title: '20 Years of WordPress: the Secret to the Success of small online businesses with selling websites',
		slug: '20-years-of-wordpress-the-secret-to-the-success-of-small-online-businesses-with-selling-websites',
		date:'8 May 2023',
		category: 'wordpress',
		content: <WpAnniversary />
	},
	{
		imgs: {
			v: require('../imgs/blog/how-deal-nft.webp'),
			h: require('../imgs/blog/how-deal-nft-h.webp')
		},
		title: 'How to deal with your online Project based on NFT website templates?',
		slug: 'how-deal-your-online-project-based-nft-website-templates',
		date:'28 January 2023',
		category: 'crypto',
		content: <HowDealNft />
	},
	{
		imgs: {
			v: require('../imgs/blog/top-black-friday-2022.webp'),
			h: require('../imgs/blog/top-black-friday-2022-h.webp')
		},
		title: 'Top Instruments to get on TemplateMonster Black Friday from Travolgi',
		slug: 'top-instruments-to-get-on-templatemonster-black-friday-from-travolgi',
		date:'27 November 2022',
		category: 'discounts',
		content: <TopBlackFriday2022 />
	},
	{
		imgs: {
			v: require('../imgs/blog/halloween-sale-template-monster.webp'),
			h: require('../imgs/blog/halloween-sale-template-monster-h.webp')
		},
		title: 'Frighteningly amazing Discounts are waiting for you at Template Monster!',
		slug: 'frighteningly-amazing-discounts-for-you-templatemonster',
		date:'25 October 2022',
		category: 'discounts',
		content: <HalloweenSaleTemplateMonster />
	},
   {
		imgs: {
			v: require('../imgs/blog/holiday-deals-travolgi.webp'),
			h: require('../imgs/blog/holiday-deals-travolgi-h.webp')
		},
		title: 'Ghostly greetings to Halloween lovers: make sure to get the best Holiday Deals from Travolgi!',
		slug: 'ghostly-greetings-halloween-lovers-make-sure-get-best-holiday-deals-from-travolgi',
		date:'22 October 2022',
		category: 'about',
		content: <HolidayDealsTravolgi />
	},
	{
		imgs: {
			v: require('../imgs/blog/why-have-site.webp'),
			h: require('../imgs/blog/why-have-site-h.webp')
		},
		title: 'Why you should have a website',
		slug: 'why-you-should-have-a-website',
		date:'20 September 2022',
		category: 'perplexity',
		content: <WhyHaveWebsite />
	}
]
